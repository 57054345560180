import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RppLabelFormErrorComponent } from '@/presentation/atoms/texts/label-error.component';
import { CustomErrorMessages } from '@/core/interfaces/input.interface';
import { ValidatorsService } from '@/core/services/validators.service';

@Component({
  selector: 'rpp-form-error-message',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RppLabelFormErrorComponent,
  ],
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss'],
})
export class RppErrorMessageFormComponent {
  @HostBinding('class') class = 'block mt-2';
  @Input({ required: true }) control!: FormControl;
  @Input({ required: true }) controlLabel: string = '';
  @Input() formGroup!: FormGroup;
  @Input() customErrorMessages!: CustomErrorMessages;

  constructor(public validators: ValidatorsService) {}

  getErrorMessage(errorName: string): string {
    const validationStrategy = this.validators.validationStrategy(
      this.controlLabel,
      this.control,
      this.customErrorMessages
    );
    return validationStrategy[errorName] || 'Error no identificado';
  }
}
