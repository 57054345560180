import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { RppLabelInputFormComponent } from '@/presentation/atoms/texts/label-input-form.component';
import { RppErrorMessageFormComponent } from '@/presentation/molecules/form-error-message/form-error-message.component';

@Component({
  selector: 'rpp-form-input-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    RppLabelInputFormComponent,
    RppErrorMessageFormComponent,
  ],
  templateUrl: './form-input-wrapper.component.html',
  styleUrl: './form-input-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RppFormInputWrapperComponent {
  @Input({ required: true }) label = '';
  @Input() sublabel = '';
  @Input() control!: FormControl;
  @Input() id = '';
  @Input() required = true;
  @Input() disabled: boolean = false;
  @Input() hasValidation: boolean = true;

  private subscription: Subscription = new Subscription();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.control) return;

    this.subscription.add(
      this.control.statusChanges.subscribe(() => {
        this.cdr.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
