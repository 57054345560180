import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { CustomErrorMessages } from '../interfaces/input.interface';

@Injectable({ providedIn: 'root' })
export class ValidatorsService {
  public emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

  public showErrorField(control: FormControl, errorName: string) {
    return control.hasError(errorName) && (control.dirty || control.touched);
  }

  private defaultLabelFormValidations(label: string, control: FormControl) {
    const requiredMinLength = control?.getError('minlength')?.requiredLength;
    const requiredMaxLength = control?.getError('maxlength')?.requiredLength;
    const requiredMin = control?.getError('min')?.min;
    const requiredMax = control?.getError('max')?.max;

    return {
      required: `${label} es requerido/a`,
      email: `${label} es inválido`,
      minlength: `${label} debe tener al menos ${requiredMinLength} caracteres`,
      maxlength: `${label} debe tener un máximo ${requiredMaxLength} caracteres`,
      min: `${label} debe ser mayor o igual a ${requiredMin}`,
      max: `${label} debe ser menor o igual a ${requiredMax}`,
      invalidDescriptionFormat: `Formato de ${label.toLowerCase()} inválido`,
    };
  }

  public validationStrategy(
    label: string,
    control: FormControl,
    customErrorMessages?: CustomErrorMessages
  ): { [key in string]: string | null } {
    return {
      required:
        customErrorMessages?.required ||
        this.defaultLabelFormValidations(label, control).required,
      email:
        customErrorMessages?.email ||
        this.defaultLabelFormValidations(label, control).email,
      minlength:
        customErrorMessages?.minlength ||
        this.defaultLabelFormValidations(label, control).minlength,
      maxlength:
        customErrorMessages?.maxlength ||
        this.defaultLabelFormValidations(label, control).maxlength,
      min:
        customErrorMessages?.min ||
        this.defaultLabelFormValidations(label, control).min,
      max:
        customErrorMessages?.max ||
        this.defaultLabelFormValidations(label, control).max,
      invalidDescriptionFormat: this.defaultLabelFormValidations(label, control)
        .invalidDescriptionFormat,
    };
  }

  public invalidDescriptionFormatValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) return null; // si no hay valor

      const hasLeadingTrailingSpace =
        value.startsWith(' ') || value.endsWith(' ');
      const hasMultipleSpaces = / {2,}/.test(value);

      if (hasLeadingTrailingSpace || hasMultipleSpaces) {
        return {
          invalidDescriptionFormat: true, // si cualquiera de las 2 condiciones se cumple
        };
      }

      return null; // si ninguna condición se cumple
    };
  }
}
