<div class="field p-fluid">
  <rpp-label-input
    [label]="label"
    [sublabel]="sublabel"
    [required]="required"
    [for]="id"
    class="mb-2"
    [disabled]="disabled"
  />
  <ng-content />
  @if (hasValidation) {
    <rpp-form-error-message [control]="control" [controlLabel]="label" />
  }
</div>

<!-- <div>form Input wrapper value {{ control.value }}</div>

<div>form Input wrapper dirty: {{ control.dirty }}</div>

<div>form Input wrapper touched: {{ control.touched }}</div>

<div>form Input wrapper errors: {{ control.errors | json }}</div> -->

<!-- <br /> -->
