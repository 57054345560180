<ng-container
  *ngIf="control.hasError('required')"
  [ngTemplateOutlet]="formError"
  [ngTemplateOutletContext]="{
    validation: 'required',
    control: control
  }"
/>

<ng-container
  *ngIf="control.hasError('email')"
  [ngTemplateOutlet]="formError"
  [ngTemplateOutletContext]="{
    validation: 'email',
    control: control
  }"
/>

<ng-container
  *ngIf="control.hasError('minlength')"
  [ngTemplateOutlet]="formError"
  [ngTemplateOutletContext]="{
    validation: 'minlength',
    control: control
  }"
/>

<ng-container
  *ngIf="control.hasError('maxlength')"
  [ngTemplateOutlet]="formError"
  [ngTemplateOutletContext]="{
    validation: 'maxlength',
    control: control
  }"
/>

@if(control.hasError('invalidDescriptionFormat') &&
(!control.hasError('minlength') && (!control.hasError('maxlength')))){
<ng-container
  [ngTemplateOutlet]="formError"
  [ngTemplateOutletContext]="{
    validation: 'invalidDescriptionFormat',
    control: control
  }"
/>
}

<ng-template #formError let-control="control" let-validation="validation">
  <!-- <div>
    form error msg - errors {{ control.errors | json }}
  </div>
  <div>
    form error msg - required {{ control.hasError('required') }}
  </div>
  <div>
    form error msg - touched {{ control.touched }}
  </div>
  <div>
    form error msg - dirty {{ control.dirty }}
  </div>
  <br /> -->
  @if (validators.showErrorField(control, validation)) {
  <rpp-label-form-error [label]="getErrorMessage(validation)" />
  }
</ng-template>
